import { ENoteLength, ENoteType, IConsultation, IPagination } from '@/types'
import { http } from '@/utils/http'
import { AxiosResponse } from 'axios'
import qs from 'qs'

class ConsultationDataService {
  async getStatusNotification() {
    return await http.get(`/status`)
  }

  async changeNoteLength(
    id: string | null,
    length: ENoteLength
  ): Promise<
    AxiosResponse<{
      length: ENoteLength
      content: string
      status: string
      type: ENoteType
    }>
  > {
    switch (length) {
      case ENoteLength.SHORT:
        return await http.post(`/consultations/shorten/${id}`)
      case ENoteLength.STANDARD:
        return await http.post(`/consultations/standard/${id}`)
      case ENoteLength.LONG:
        return await http.post(`/consultations/lengthen/${id}`)
      default:
        return await http.post(`/consultations/standard/${id}`)
    }
  }

  async getAllConsultations(paginate: IPagination = { page: 1, size: 10 }) {
    console.log('getAllConsultations', paginate)
    const query = qs.stringify({
      ...paginate
    })

    return await http.get(`/consultations?${query}`)
  }
  async createConsultation(body: any) {
    return await http.post(`/consultations`, body)
  }
  async copyConsultation(id: string) {
    return await http.post(`/consultations/copy-notes/${id}`)
  }
  async deleteConsultation(id: string) {
    return await http.delete(`/consultations/${id}`)
  }
  async getConsultationById(id: string) {
    let deviceType = 'mobile'
    if (!/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      deviceType = 'desktop'
    }
    return await http.get<IConsultation>(`/consultations/${id}?deviceType=${deviceType}`)
  }
  async updateConsultation(consultation: any) {
    return await http.put(`/consultations/notes/${consultation._id}`, consultation)
  }
  async getRecording() {
    return await http.get(`/audio-recording`)
  }
  async updateRecordingStatus(consultationId: string, newStatus: string, elapsedTime: number) {
    return await http.post(`/recording/status`, {
      consultationId: consultationId,
      newStatus: newStatus,
      elapsedTime: elapsedTime
    })
  }
  async changeNoteType(
    id: string | null | undefined,
    noteType: ENoteType
  ): Promise<
    AxiosResponse<{
      length: ENoteLength
      content: string
      status: string
      type: ENoteType
    }>
  > {
    return await http.post(`/consultations/template/${id}?notes_type=${noteType}`)
  }

  async sendAudioChunkToServer(form: FormData) {
    return await http.post(`/upload-chunk`, form, {
      headers: {
        'Content-Type': `multipart/form-data`
      }
    })
  }
  async consolidateAudio(form: FormData) {
    return await http.post(`/consolidate-audiofile`, form, {
      headers: {
        'Content-Type': `multipart/form-data`
      }
    })
  }
  async createPublicRecording(data: { password: string; file: Blob }) {
    return await http.post(`/audio-recording/public`, data, {
      headers: {
        'Content-Type': `multipart/form-data`
      }
    })
  }

  async giveSummaryFeedback(data: { consultationId: string; thumbUp: boolean; feedback: string }) {
    return await http.post(`/consultations/feedback`, data)
  }

  async getSummaryFeedback(consultationId: string) {
    return await http.get<{
      _id: string
      consultationId: string
      thumbUp: boolean
      feedback: string
      userId: string
      creationDate: string
    }>(`/consultations/feedback/${consultationId}`)
  }
}

export default new ConsultationDataService()
