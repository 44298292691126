import { IUserData } from '@/types'
import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { toaster } from '@/utils/toaster'

export const useUserDataStore = defineStore('userData', () => {
  const userData = reactive<IUserData>({
    access_token: null,
    token_type: null,
    user: null,
    locale: undefined
  })
  const { t, availableLocales } = useI18n({ useScope: 'global' })

  const isLoggedIn = computed(
    () => !!(userData.user && userData.access_token && userData.token_type)
  )

  function setUserData(newUserData: IUserData) {
    localStorage.setItem('userData', JSON.stringify(newUserData))

    userData.access_token = newUserData.access_token
    userData.token_type = newUserData.token_type
    userData.user = newUserData.user
    if (newUserData.user?.lang) {
      userData.locale = newUserData.user.lang
    }
  }

  function clearUserData() {
    userData.access_token = null
    userData.token_type = null
    userData.user = null
    userData.locale = undefined
    localStorage.removeItem('userData')
  }

  function setUserDataFromLocalStorage() {
    const uDate = localStorage.getItem('userData')

    if (!uDate) {
      return
    }

    setUserData(JSON.parse(uDate))
  }

  function changeLocale(newLocale?: string) {
    const isLocaleSupported = newLocale && availableLocales?.includes(newLocale)

    if (isLocaleSupported) {
      userData.locale = newLocale

      if (userData.user)
        setUserData({
          ...userData,
          ...(userData.user && {
            user: {
              ...(userData.user || {}),
              lang: newLocale
            }
          })
        })
      return
    }

    toaster.error(t('locale.notSupported'))
  }

  return {
    userData,
    isLoggedIn,
    setUserData,
    clearUserData,
    setUserDataFromLocalStorage,
    changeLocale
  }
})
