import { computed, watch, WritableComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import useWeakCookies from '@/composables/useWeakCookies'
import { useUserDataStore } from '@/stores/user'

export default function useCookieLocale() {
  const { setCookie, getCookie } = useWeakCookies()
  const { locale, availableLocales } = useI18n({ useScope: 'global' })
  const userStore = useUserDataStore()

  const currentLang: WritableComputedRef<string | undefined | null> = computed({
    get: () => getCookie('currentLang'),
    set(newValue?: string | null) {
      setCookie(
        'currentLang',
        newValue && availableLocales.includes(newValue) ? newValue : locale.value
      )
    }
  })

  watch(
    () => userStore?.userData?.locale,
    (newValue?: string) => {
      if (newValue && availableLocales.includes(newValue)) {
        currentLang.value = newValue
        locale.value = newValue
      }
    }
  )

  return {
    currentLang
  }
}
