<template>
  <div
    class="relative flex flex-col overflow-hidden border-gray-200 lg:rounded-lg lg:border"
    :class="{ 'bg-accent-500/10': consultationsStore.selectedConsultation }"
  >
    <div
      v-if="consultationsStore.selectedConsultation"
      class="z-10 h-[64px] shrink-0 border-b border-gray-200 bg-white px-6 py-4 lg:px-8 xl:h-[80px]"
    >
      <div class="flex gap-4">
        <button
          type="button"
          class="inline-flex h-8 w-8 shrink-0 grow-0 items-center justify-center rounded-full border border-gray-200 text-gray-500 hover:border-black hover:text-black lg:hidden"
          @click="consultationsStore.handleShowListOfNotes"
        >
          <ArrowLeftIcon class="h-6 w-6" />
        </button>

        <div class="grow">
          <input
            v-model="descriptionControl"
            :disabled="
              consultationsStore.loadingConsultation ||
              !consultationsStore.selectedConsultation?.summary
            "
            class="block w-full truncate rounded-sm border-none border-transparent pr-5 text-xl font-bold outline-none ring-1 ring-transparent focus:-mx-2 focus:px-2 focus:ring-accent-500"
            @keydown.enter="handlePressEnter"
            @blur="handleUpdateDescription"
          />

          <div class="flex max-w-full items-center gap-3 overflow-hidden text-sm">
            {{ $t('consultationIndexView.details.notes') }}
          </div>
        </div>
      </div>
    </div>

    <BaseSpinner
      v-if="updating"
      class="absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center bg-gray-500/15"
    />

    <BaseSpinner v-if="consultationsStore.loadingConsultation">
      <div class="mb-2 text-center text-2xl">{{ $t('generalTips.loading') }}</div>
    </BaseSpinner>

    <div
      v-else-if="consultationsStore.selectedConsultation?.status === 'error'"
      class="left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center bg-gray-500/15 px-12"
    >
      <div class="mb-2 text-center text-2xl">
        {{ $t('consultationIndexView.waitingScreens.errorHappened.title') }}
      </div>
      <div class="px-3 text-center text-lg">
        {{ $t('consultationIndexView.waitingScreens.errorHappened.description') }}
      </div>
      <div class="mb-8 px-3 text-center text-lg">
        {{ $t('consultationIndexView.waitingScreens.errorHappened.description2') }}
      </div>
    </div>

    <BaseSpinner
      v-else-if="isConsultationInProgress"
      class="left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center bg-gray-500/15 px-12"
      :with-icon="false"
    >
      <div class="mb-2 text-center text-2xl">
        {{ $t('consultationIndexView.waitingScreens.aiProcessing.title') }}
      </div>
      <div class="mb-8 px-3 text-center text-lg">
        {{ $t('consultationIndexView.waitingScreens.aiProcessing.description') }}
      </div>
    </BaseSpinner>

    <div
      v-else
      class="flex h-[calc(100%-64px)] grow justify-between gap-5 overflow-y-auto !p-8 xl:h-[calc(100%-80px)]"
    >
      <ul class="flex max-w-full shrink grow flex-col gap-3 lg:max-w-3xl">
        <li v-if="(consultationsStore.selectedConsultation?.lostSeconds || 0) > 0">
          <div
            class="flex items-center gap-x-3 rounded-lg border border-[#967032] bg-[#F8F3D6] px-6 py-2 text-[#967032]"
          >
            <ExclamationTriangleIcon class="size-6 shrink-0" />

            <div>
              <i18n-t keypath="consultationIndexView.details.lostTimeNotification" tag="p">
                <template #time>
                  <span class="font-bold"> {{ lostTime }}</span>
                </template>

                <template #link>
                  <a
                    target="_blank"
                    :href="fixAudioURL"
                    class="break-all font-bold italic hover:cursor-pointer hover:opacity-70"
                  >
                    {{ t('consultationIndexView.details.here') }}
                  </a>
                </template>
              </i18n-t>
            </div>
          </div>
        </li>
        <li v-if="consultationsStore.selectedConsultation?.badQuality">
          <div
            role="alert"
            aria-live="polite"
            class="flex items-center gap-x-3 rounded-lg border border-[#967032] bg-[#F8F3D6] px-6 py-2 text-[#967032]"
          >
            <ExclamationTriangleIcon class="size-6 shrink-0" aria-hidden="true" />
            <div>
              <i18n-t keypath="consultationIndexView.details.badAudioQualityNotification" tag="p">
              </i18n-t>
            </div>
          </div>
        </li>
        <li v-if="(consultationsStore.selectedConsultation?.consultationDuration || 0) > 124 * 60">
          <div
            class="flex items-center gap-x-3 rounded-lg border border-[#967032] bg-[#F8F3D6] px-6 py-2 text-[#967032]"
          >
            <ExclamationTriangleIcon class="size-6 shrink-0" />
            <div>
              <i18n-t
                keypath="consultationIndexView.details.maxLengthConsultationNotification"
                tag="p"
              />
            </div>
          </div>
        </li>

        <li>
          <div class="relative rounded-lg border border-accent-500 bg-white p-6">
            <div
              class="flex justify-between gap-6 border-accent-500/10"
              :class="{ 'border-b pb-3': !isCollapsed }"
            >
              <div class="grow text-ellipsis text-2xl font-semibold text-accent-500">
                {{
                  $t(
                    `consultationIndexView.details.noteTypesTitle.${consultationsStore.selectedConsultation?.noteType}`
                  )
                }}
              </div>
              <!--button
                type="button"
                class="h-8 w-8 shrink-0 grow-0 text-accent-500"
                @click.stop="isCollapsed = !isCollapsed"
              >
                <ChevronDownIcon class="h-6 w-6" :class="{ '!rotate-180': !isCollapsed }" />
              </button-->
            </div>

            <BaseTextarea v-show="!isCollapsed" v-model="summaryControl" />
          </div>

          <div class="mb-8 mt-2.5 flex justify-end gap-4">
            <SummaryFeedback
              v-if="consultationsStore.selectedConsultation"
              :consultationId="consultationsStore.selectedConsultation!._id"
            />

            <button
              v-if="canSave"
              type="button"
              :disabled="updating"
              class="relative flex items-center gap-3 rounded-md border border-accent-500 bg-white/20 px-4 py-2 text-accent-500 hover:bg-accent-500/30"
              @click="handleUpdateSummary"
            >
              <InboxArrowDownIcon class="h-6 w-6" />
              {{ $t('consultationIndexView.details.save') }}

              <BaseSpinner v-show="updating" class="absolute" />
            </button>

            <BaseCopyButton
              v-if="consultationsStore.selectedConsultation?._id"
              :disabled="updating"
              :text="summaryControl || ''"
              @copy="
                consultationDataService.copyConsultation(
                  consultationsStore.selectedConsultation?._id
                )
              "
            />
          </div>
        </li>
      </ul>

      <div
        v-if="
          userDataStore?.userData?.user?.activeFeatures?.notesTypeChoice ||
          userDataStore?.userData?.user?.activeFeatures?.notesLengthChoice
        "
        class="sticky top-0 flex shrink-0 flex-col items-end gap-6 max-lg:hidden"
      >
        <h3 class="text-semibold self-start text-2xl">
          {{ $t('consultationIndexView.recordingProcess.noteSettings.title') }}
        </h3>

        <div
          v-if="userDataStore?.userData?.user?.activeFeatures?.notesTypeChoice"
          class="z-10 w-52"
        >
          <BaseSelect
            :disabled="updating"
            :model-value="consultationsStore?.selectedConsultation?.noteType"
            placeholder="Select note type"
            :options="noteTypesOptions"
            @update:model-value="handleUpdateNoteType"
          >
            <template #label>
              <span>
                {{ $t('consultationIndexView.recordingProcess.noteSettings.template') }}
              </span>

              <BaseDialog type="success" :cancelText="null">
                <BaseMarkdownRender :markdown="markdown" />

                <template #activator="{ toggleVisibility }">
                  <button
                    class="block h-5 w-5 rounded-full bg-transparent text-accent-500 hover:opacity-85"
                    @click.stop="toggleVisibility"
                  >
                    <InformationCircleIcon class="block h-5 w-5" />
                  </button>
                </template>
              </BaseDialog>
            </template>
          </BaseSelect>
        </div>

        <div
          v-if="userDataStore?.userData?.user?.activeFeatures?.notesLengthChoice"
          class="self-stretch"
        >
          <label class="mb-1 block text-base">
            {{ $t('consultationIndexView.recordingProcess.noteSettings.noteLength') }}
          </label>

          <div class="flex items-stretch justify-stretch">
            <BaseTooltip
              v-for="noteLengthOption in noteLengthOptions"
              :key="noteLengthOption.value"
              :tooltip="noteLengthOption.tip"
              is-on-hover
              class="group"
              placement="bottom"
            >
              <button
                type="button"
                class="grow border-y border-l border-accent-500 px-4 py-3 text-lg font-medium text-accent-500 hover:bg-accent-500/30 group-first:rounded-l-md group-last:rounded-r-md group-last:border-r"
                :class="{
                  'bg-accent-500/30 text-accent-500':
                    noteLengthOption.value === consultationsStore?.selectedConsultation?.noteLength
                }"
                @click="handleChangeNoteLength(noteLengthOption.value)"
              >
                <component :is="noteLengthOption.icon" class="size-6" />
              </button>
            </BaseTooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type Component, computed, ComputedRef, ref, watch } from 'vue'
import {
  ArrowLeftIcon,
  // ChevronDownIcon,
  DocumentMinusIcon,
  DocumentPlusIcon,
  DocumentIcon,
  InboxArrowDownIcon,
  InformationCircleIcon
} from '@heroicons/vue/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
import BaseCopyButton from '@/components/base/BaseCopyButton.vue'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import SummaryFeedback from '@/components/SummaryFeedback.vue'
import { ENoteLength, ENoteType, IConsultation } from '@/types'
import consultationDataService from '@/services/consultationDataService'
import { toaster } from '@/utils/toaster'
import { logError } from '@/utils/error-logger'
import { useConsultationsStore } from '@/stores/consultations'
import { useI18n } from 'vue-i18n'
import { useUserDataStore } from '@/stores/user'
import BaseTooltip from './base/BaseTooltip.vue'
import BaseSelect from './base/BaseSelect.vue'
import { useMarkdownContent } from '@/composables/useMarkdownContent'
import BaseDialog from './base/BaseDialog.vue'
import BaseMarkdownRender from './base/BaseMarkdownRender.vue'

const { t } = useI18n()
const consultationsStore = useConsultationsStore()
const userDataStore = useUserDataStore()
const { markdown } = useMarkdownContent('./notes-template-tooltip/')

const isCollapsed = ref<boolean>(false)
const updating = ref<boolean>(false)
const summaryControl = ref('')
const descriptionControl = ref('')

const noteLengthOptions: { tip: string; icon: Component; value: ENoteLength }[] = [
  {
    tip: t('consultationIndexView.recordingProcess.noteSettings.lengthsTip.short'),
    icon: DocumentMinusIcon,
    value: ENoteLength.SHORT
  },
  {
    tip: t('consultationIndexView.recordingProcess.noteSettings.lengthsTip.standard'),
    icon: DocumentIcon,
    value: ENoteLength.STANDARD
  },
  {
    tip: t('consultationIndexView.recordingProcess.noteSettings.lengthsTip.long'),
    icon: DocumentPlusIcon,
    value: ENoteLength.LONG
  }
]

const noteTypesOptions: ComputedRef<{ label: string; value: string }[]> = computed(() => {
  const typesOptions = userDataStore.userData.user?.availableNoteTypes || []
  // Sort by position
  typesOptions.sort((a, b) => a.position - b.position)

  return (typesOptions || []).map((item) => ({
    label: t(`consultationIndexView.details.noteTypesTitle.${item.name}`),
    value: item.name
  }))
})

const canSave = computed(
  () => consultationsStore.selectedConsultation?.summary !== summaryControl.value
)
const lostTime = computed(() => {
  const lostSeconds = consultationsStore.selectedConsultation?.lostSeconds || 0
  if (lostSeconds >= 60) {
    const minutes = Math.floor(lostSeconds / 60)
    return minutes === 1 ? `${minutes} min` : `${minutes} mins`
  }
  return `${lostSeconds} sec`
})
const fixAudioURL = computed(() => import.meta.env?.VITE_LINK_TO_FIX_LOST_AUDIO)

const isConsultationInProgress = computed(
  () => !['ready', 'error'].includes(consultationsStore.selectedConsultation?.status || 'error')
)

watch(
  () => consultationsStore.selectedConsultation,
  (newConsultation) => {
    summaryControl.value = newConsultation?.summary || ''
    descriptionControl.value = newConsultation?.description || ''
  },
  { immediate: true, deep: true }
)

watch(
  () => consultationsStore.selectedConsultationId,
  () => {
    updating.value = false
  }
)

async function handleUpdateNoteType(noteType: ENoteType) {
  try {
    updating.value = true
    await consultationsStore.changeConsultationNoteType(noteType)
  } catch (error: any) {
    toaster.error(t('consultationIndexView.details.toaster.noteTypeUpdateError'))

    logError(
      'Error with updating consultation note type',
      'ConsultationDetails.vue:handleUpdateNoteType()',
      error?.response?.data || error?.response || error
    )
  } finally {
    updating.value = false
  }
}

async function handleChangeNoteLength(noteLength: ENoteLength) {
  try {
    updating.value = true
    await consultationsStore.changeConsultationSummaryLength(noteLength)
  } catch (error: any) {
    toaster.error(t('consultationIndexView.details.toaster.noteLengthUpdateError'))

    logError(
      'Error with updating consultation note length',
      'ConsultationDetails.vue:handleChangeNoteLength()',
      error?.response?.data || error?.response || error
    )
  } finally {
    updating.value = false
  }
}

function handleUpdateSummary() {
  handleSaveConsultation({ summary: summaryControl.value })
}

function handlePressEnter(event: any) {
  event.target?.blur()
}

function handleUpdateDescription() {
  if (!descriptionControl.value) {
    toaster.warn(t('consultationIndexView.details.toaster.descriptionEmpty'))
    descriptionControl.value = consultationsStore.selectedConsultation?.description || ''
    return
  }
  if (descriptionControl.value !== consultationsStore.selectedConsultation?.description) {
    handleSaveConsultation({ description: descriptionControl.value, summary: summaryControl.value })
  }
}

async function handleSaveConsultation(updatedFields: Partial<IConsultation>) {
  try {
    updating.value = true
    const { data } = await consultationDataService.updateConsultation({
      ...consultationsStore.selectedConsultation,
      ...updatedFields
    })
    consultationsStore.handleUpdateConsultation(data)

    toaster.success(t('consultationIndexView.details.toaster.notesUpdated'))
  } catch (error: any) {
    toaster.error(t('consultationIndexView.details.toaster.editNotSaved'))
    logError(
      'Error with updating consultation summary',
      'ConsultationDetails.vue:handleSaveConsultation()',
      error?.response?.data || error?.response || error
    )
  } finally {
    updating.value = false
  }
}
</script>
